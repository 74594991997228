// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

// h4 b{
//     text-transform: capitalize;
// }
.topBarImageAlignment {
    text-align: center;
}

.cradBodres {
    border: 1px solid $color-template !important;
}

.paymentColor {
    color: $color-template !important;
    font-weight: 600;
}

body a {
    color: $color-template !important;
    text-decoration: none;
}

body a:hover {
    color: #007ad9 !important;
    text-decoration: none;
}

.fontWeight {
    font-weight: bold;
    text-transform: capitalize;
}

.paddingRowDataCol {
    text-align: left;
    padding: 5px 5px;
}

.paddingOfRowData {
    padding: 5px 0;
    text-align: left;
    word-break: break-all;
}

.addressDetailsBg {
    background-color: #0000ff0d !important;
}

img {
    margin-top: 0 !important;
}

.p-fileupload-buttonbar button:not(.p-fileupload-choose) {
    display: none !important;
}

.box {
    background-color: #80808047;
    padding: 6.25rem 1.25rem;
    display: flex;
    justify-content: center;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.mindivForMultipleupload {
    width: 100%;
    // overflow: auto;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
}

.timesCircleIcon {
    font-size: 14px;
    position: absolute;
    left: 20px;
    top: 23px;
    color: cadetblue;
    cursor: pointer;
}

.dotsCircle {
    height: 100px;
    width: 100px;
    background-color: #bbb;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputfile+label {
    max-width: 80%;
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
}

body a:hover {
    color: $color-template;
}

.studentsDelteIcon {
    position: absolute;
    // right: 2px;
    top: 78px;
    left: -10px;
}

.theme-light .form input,
.theme-light .form textarea {
    color: black;
}

.blackColor {
    color: black;
    font-weight: 500;
}

.form-check-inline {
    align-items: start !important;
}

.collapseDivAdjust {
    background-color: $color-template;
    color: $color-white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.collapseMainDivAdjustment {
    border: 1px solid $color-template;
    margin-top: 5px;
    border-radius: 6px;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.productsHeight {
    height: 330px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button:hover {
    background-color: $color-template;
    border-color: $color-template
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: $color-template;
    border-color: $color-template
}

.paddingTopProfile {
    padding-top: 2px;
}

// .p-highlightRow{
// background-color:grey !important;
// color: white !important;
// }
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    margin-bottom: 0 !important;
}

body .p-multiselect .p-multiselect-label {
    padding-top: 9px !important;
}

.p-fileupload-content {
    height: 170px !important;
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
}

body .p-fileupload-choose:not(.p-disabled):hover {
    background-color: $color-template !important;

}

.rbc-today {
    background-color: transparent !important;
}

body .p-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
    border-right: 0 none;
    width: calc(100% - 2.357em);
    text-transform: capitalize !important;
}

body .p-button:hover {
    background-color: $color-template;
    border-color: $color-template
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize !important;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: $color-template;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 500;
}

.p-dropdown .p-dropdown-trigger {
    height: 100%;
}

// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.p-multiselect-label-container {
    height: 30px;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    max-width: 400px;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
}

.p-datatable-row:hover {
    background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

.nav-item {
    .nav-link {
        cursor: pointer;
    }

    .nav-link.active {
        cursor: default;
    }
}

.detailsImgStyle {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: solid 1px gray;
}

.fullWidth {
    width: 100%;
}

.mindivForMultipleupload .timesCircleIcon {
    font-size: 18px !important;
    left: 22px;
    top: 22px;
    background-color: $color-white;
    color: $color-template;
    border-radius: 10px;
}

.react-select__control {
    min-height: 32px !important;
    border-radius: 4px !important;
}

.react-select__value-container {
    padding: 8px !important;
}
.viewModalObjectDiv{
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    padding: 4px 10px;
}
.css-1g6gooi {
    margin: 0px !important;
    padding: 0px !important;
    height: 20px !important;
}

.deleteIcon {
    color: #bf1725;
    cursor: pointer;
}

.addIcon {
    color: #024a88;
    cursor: pointer;
}

.borderRadius5 {
    border-radius: 5px;
}

.sameFamNameCheckBox {
    justify-content: space-between;
    align-items: center;

    svg {
        margin-top: -4px;
    }
}

.rowEditIcon {
    color: $color-row-edit-icon;
    width: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.rowShareIcon {
    color: $color-row-share-icon;
    width: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.rowCopyIcon {
    color: $color-row-copy-icon;
    width: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.selectedRowEditIcon {
    color: white;
    width: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.rowDeleteIcon {
    color: $color-row-delete-icon;
    width: 13px;
    cursor: pointer;
    margin-left: 10px;
}

.rowDivorcedIcon {
    color: $color-row-divorce-icon;
    width: 13px;
    margin-left: 10px;
}

.rowNotDivorceIcon {
    color: $color-template;
    width: 13px;
    cursor: pointer;
    margin-left: 10px;
}

.confirmationModal .modal-content {
    padding: 5px;
}

.checkCircleSuccess {
    color: green;
    width: 13px;
    margin-left: 10px;
}

.checkCircleAction {
    color: red;
    width: 13px;
    margin-left: 10px;
    cursor: pointer;
}

.checkCirclePending {
    color: orange;
    width: 13px;
    margin-left: 10px;
}

.familyClearIcon {
    color: orange;
    width: 13px;
    margin-left: 10px;
    cursor: pointer;
}

.rowUserBanIconEnable {
    color: red;
    width: 13px;
    margin-left: 10px;
    cursor: pointer;
}

.rowUserBanIconDisable {
    color: gray;
    width: 13px;
    margin-left: 10px;
    cursor: pointer;
}

.rowUserResetIcon {
    color: orange;
    width: 13px;
    margin-left: 10px;
    cursor: pointer;
}

.userPwdResetModal .modal-content {
    padding: 35px 40px 25px 40px
}

.userPwdResetModal .form {
    width: 400px;
    margin: auto;
}

.justifyFlexStart {
    justify-content: flex-start !important;
}

.justifyFlexEnd {
    justify-content: flex-end !important;
}

.p-datatable-emptymessage {
    text-align: center !important;
}

.selectedRowColor {
    color: white;
    font-weight: 500;
    cursor: "pointer";
    textTransform: "capitalize";

}

.whiteColor {
    color: white !important;
}

.whiteColor:hover {
    color: white !important;
}

.react-select__single-value {
    //top:50% !important;
    text-align: justify !important;
    display: block;
}
.student .custom-table th,td{
max-width: 190px;
min-width: 190px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
 padding: 5px!important;
}
.table_static th,td{
    // max-width: 100px;
width: 100px!important;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
 padding: 5px!important;
}

.sidebar__link-title{
    padding-right: 20px !important;
}
.css-dvua67-singleValue {
 margin-top:4px !important
   
}

//ck editor changes
.cke_dialog {
    width: 420px !important;
}

.cke_dialog_body {
    max-height: 750px !important; 
    overflow-y: auto !important;
}
.cke_dialog_tabs{
    position: relative !important;
}

.cke_ltr .cke_dialog_ui_hbox_first{
    display: inline-flex !important;
    padding-left: 1px !important;
}

input.cke_dialog_ui_input_text{
    width:150px !important;
    padding-left: 2px;
}


//ck editor scss
.cke_dialog_footer{
    text-align: center;
    position: relative;
    border-top: 1px solid #d1d1d1;
    background: #f8f8f8;
    display: contents !important;
   justify-content: center;
}

.cke_dialog_ui_hbox cke_dialog_footer_buttons{
    max-width: 10px !important;
}



.cke_dialog_ui_hbox_first{
    max-width: 10px !important;
}
.cke_dialog_tabs{
    width:50% !important;
}

.cke_preview{
    display: none !important;
}

.cke_dialog_ui_html{
    display: none !important;
}
.cke_dialog_ui_vbox_child {
    display: contents !important;
}
.cke_dialog_contents_body {
    padding-left: 10px !important;
}
